// persistConfig.js
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import tokenReducer from './reducers/tokenReducer';

const persistConfig = {
  key: 'root', // the key used to store the data in localStorage
  storage, // the storage adapter, defaults to localStorage
  whitelist: ['tokenReducer'], // an array of reducers to persist
  // version: 1, // Add a version number
};

export default persistConfig;
