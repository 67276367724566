import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../withRouter';
import { setImageArray } from '../../Redux/actions/imageArr';
import { setClickedImage } from '../../Redux/actions/clickedImage';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import Cookies from 'js-cookie';
import ImageEditorPage from '../imageEditor/ImageEditor';
import axios from 'axios';
import { configuration } from '../../appConfig';
import LanguageContext from "../../context/LanguageProvider";
import { setToken } from "../../Redux/actions/token";
import { Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from 'reactstrap';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { setPendingNotification, setCompletedNotification } from "../../Redux/actions/notificationAction";
import { CookiesRemove } from '../../CookiesRemove';

export class PendingView extends Component {
    static contextType = LanguageContext;

    constructor(props) {
        super(props);
        this.canvasRef = createRef();
        this.state = {
            imageArr: [],
            currentSlide: 0,
            imageEditorKey: 1,
            isImageClicked: 0,
            modal: false,
            isSaving: false,
            showCheckManuallyModal: false,
            showFailModal: false,
            showPassModal: false,
            loading: false,
            imageNameDisplay:"",
            selectedType:"damage",
            paintCount:0,
            damageCount:0
        };
    }

    componentDidMount() {
        const { ImageArr } = this.props;
        this.setState({ imageArr: ImageArr });
    }

    componentDidUpdate(prevProps) {
        const { ImageArr } = this.props;
        if (prevProps.ImageArr !== ImageArr) {
            this.setState({ imageArr: ImageArr });
        }
    }

    handlePrevious = () => {
        this.setState((prevState) => ({
            currentSlide: prevState.currentSlide - 1,
        }));
    };

    handleNext = () => {
        this.setState((prevState) => ({
            currentSlide: prevState.currentSlide + 1,
        }));
    };

    handleImageClick = async (uuid,name) => {
        Cookies.set('clickedCarouselUUID', uuid)
        const clickedUUID = Cookies.get('clickedUUID');
        const clickedNotification = Cookies.get('clickedNotification');
        const cuuid = Cookies.get('clickedCarouselUUID');

        const clickedImage = await this.state.imageArr.find((data) => data.uuid === uuid);
        await this.props.setClickedImage(clickedImage.imageLink)
        this.setState({
            imageEditorKey: this.state.imageEditorKey + 1,
            isImageClicked: 1,
            modal: clickedNotification == '1' && clickedUUID && clickedUUID.includes(cuuid) ? false : true,
            imageNameDisplay:name
        });
    };
    removeVin = () => {
        const backendUrl = localStorage.getItem('dynamic_partner_url')
        const uuid = Cookies.get('uuid');
        const partnerId = Cookies.get('partnerId');
        const url = backendUrl+configuration.unlockUserNotification;
        const token = this.props.getToken
        const headers = {
            token: `${token}`,
        };
        const formData = new FormData();
        formData.append('uuid', uuid)
        formData.append('partnerId', partnerId)
        axios.post(url, formData, { headers }).then((res) => {
            if (res.data.status == 200) {
                Cookies.remove('openedVin')
                this.setState({
                    locked: `${res.data.is_locked}`,
                    isImageClicked: 0
                })
                Cookies.remove('clickedCarouselUUID')
            }
            // if (res.data.status == 401) {
            //     CookiesRemove()
            //     toastAlert(res.data.message,"error");  
            //       this.props.router.navigate('/');
            //   }
        }).catch((err) => {
            console.log(err);
        });
    }
    toggleModal = () => {
        this.setState({
            modal: !this.state.modal,
            paintCount:0,
            damageCount:0,
            selectedType:"damage",
        }, () => {
            if (!this.state.modal) {
                this.removeVin();
                Cookies.remove('clickedCarouselUUID');
            }
        })
    };
// ssssssssssssssssssssssssss
    handleAction = (action) => {
        this.setState({
            loading: true
        });
        const { handleTrueOrFalse } = this.props;
        const backendUrl = localStorage.getItem('dynamic_partner_url')
        const url = backendUrl+configuration.notifyActionTaken;
        const uuid = Cookies.get('uuid');
        const partnerId = Cookies.get('partnerId')
        const payload = {
            uuid: uuid,
            reportStatus: action,
            partnerId: partnerId,
            actionTaken: 1,
        };
        Cookies.set('checkAction', action)
        const token = this.props.getToken
        const headers = {
            token: `${token}`,
        };
        axios.post(url, payload, { headers }).then((res) => {
            const { lang } = this.context;
            // console.log(res.data, 'res.data');
            if (res.data.status == 200) {
                handleTrueOrFalse(action);
                Cookies.set('actionTakenTime', res.data.actionTaken_date)
                Cookies.remove('locakedIcon')
                if (action === 'pass') {
                    toastAlert(lang('Image Pass Successfully'), 'success');
                    window.location.href = '/index'
                }
                if (action === 'fail') {
                    toastAlert(lang('Image Fail Successfully'), 'success');
                }
                if (action === 'check_manually') {
                    toastAlert(lang('Image sent to check manually'), 'success');
                }
                this.setState({
                    showPassModal: false,
                    showFailModal: false,
                    showCheckManuallyModal: false,
                    loading: false
                });
                const allDataArray = [...this.props.pendingNotification];
                const uuidToFind = Cookies.get('uuid');
                const emptyReportStatus = [];
                const nonEmptyReportStatus = [...this.props.completedNotification];
                const updatedArray = allDataArray.map((data, i) => {
                    let newObj = { ...data };
                    if (data.uuid === uuidToFind) {
                        const newObj = { ...data, reportStatus: res.data?.action_taken === 'pass' ? 'pass' : res.data?.action_taken === 'fail' ? 'fail' : 'check_manually', created_at: res.data.actionTaken_date };
                        // console.log(newObj, 'datnewObja');
                        // console.log(newObj.reportStatus, 'newObj.reportStatus');
                        if (newObj.reportStatus === '') {
                            // console.log('ggg');
                            emptyReportStatus.push(newObj);
                        } else {
                            // console.log('hhh');
                            nonEmptyReportStatus.push(newObj);
                        }
                        return newObj;
                    }
                    return newObj;
                });
                this.props.setPendingNotification(emptyReportStatus);
                this.props.setCompletedNotification(nonEmptyReportStatus);
                Cookies.remove('checkAction')
                // console.log(updatedArray, 'updatedArray');
                this.props.router.navigate('/index');
            } else {
                this.setState({
                    showPassModal: false,
                    showFailModal: false,
                    showCheckManuallyModal: false,
                    loading: false
                }, () => this.removeVin());
                let errorMessage = res.data.status === 404 ? "Notification not found" : "Something went wrong. Please try again later."
                toastAlert(errorMessage, 'error');
            }

            // if (res.data.status == 401) {
            //     CookiesRemove()
            //     toastAlert(res.data.message,"error");  
            //       this.props.router.navigate('/');
            //   }
        }).catch((err) => {
            this.setState({
                loading: false
            });
            toastAlert('Something went wrong. Please try again later.');
        });
    };
    togglePassActionModal = () => {
        this.setState({
            showPassModal: !this.state.showPassModal
        });
    }
    toggleFailActionModal = () => {
        this.setState({
            showFailModal: !this.state.showFailModal,
        });
    }
    toggleCheckManuallyModal = () => {
        this.setState({
            showCheckManuallyModal: !this.state.showCheckManuallyModal
        });
    }
    updateImage = async () => {
        const { lang } = this.context;
        const backendUrl = localStorage.getItem('dynamic_partner_url')
        const [vin, partnerId, uuid] = [Cookies.get('vin'), Cookies.get('partnerId'), Cookies.get('clickedCarouselUUID')];
        const url = backendUrl+configuration.updateImage;
        this.setState({
            isSaving: true,
        });
        const canvas = this.canvasRef.current;
        const base64Image = canvas.toDataURL('image/png')?.split('base64,')[1];

        const payload = {
            uuid,
            vin,
            partnerId,
            fileName: base64Image,
            paint_count:this.state.paintCount,
            damage_count:this.state.damageCount
        }
        axios.post(url, payload)
            .then((res) => {
                this.setState({
                    isSaving: false,
                });
                if (res.data.status == 200) {
                    this.removeVin();
                    this.setState({
                        modal: false,
                        paintCount:0,
                        damageCount:0,
                        selectedType:"damage",
                    })
                    toastAlert(lang('Image saved Successfully'), 'success');
                }
            //    else  if (res.data.status == 401) {
            //     CookiesRemove()
            //     toastAlert(res.data.message,"error");  
            //       this.props.router.navigate('/');
            //   }
                else {
                    toastAlert(lang('Something went wrong'), 'error');
                }
            })
            .catch((err) => {
                this.setState({
                    isSaving: false,
                });
                toastAlert(lang('Something went wrong'), 'error');
            })
    }
    resetZoom = () => {
        const canvas = this.canvasRef.current;
        if (canvas) {
            canvas.setZoom(1);  // Reset zoom to 1 (default zoom level)
            canvas.viewportTransform = [1, 0, 0, 1, 0, 0];  // Reset viewport transform
            canvas.renderAll();  // Re-render the canvas to apply the changes
        }
    };
    handleSelectChange = (e)=>{
        this.setState({selectedType: e.target.value})
    }
    handleSelectValueChangeFromBoxSelect = (value)=>{
        this.setState({selectedType: value})
    }
    handlePaintCountChange =(type,count)=>{
        // console.log("Called",type,count);
        
        if (type === "paint") {
            this.setState((prevState) => ({
                paintCount: count
            }), () => {
                // console.log('paint count:', this.state.paintCount);
            });
        }
        if (type === "damage") {
            this.setState((prevState) => ({
                damageCount: count
            }), () => {
                // console.log('Dent count:', this.state.damageCount);
            });
        }
    }
    render() {
        const { imageArr, currentSlide, isImageClicked } = this.state;
        const { showPassModal, showFailModal, loading, showCheckManuallyModal, isSaving } = this.state
        const vin = Cookies.get('vin');
        const { handleTrueOrFalse } = this.props;
        const clickedUUID = Cookies.get('clickedUUID')
        const clickedNotification = Cookies.get('clickedNotification')
        const cuuid = Cookies.get('clickedCarouselUUID')
        const reportStatus = Cookies.get('reportStatus')
        const { lang } = this.context;
        return (
            <>
                {loading &&
                    <div className="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>
                }
                <div className="col-lg-8 col-md-12 col-sm-12 d-flex all-underbody-col image-modal-col">
                    <div className="card-main card">
                        <div className="card-header">
                            <div className="all-title-close-btn">
                                <div className="all-title car-title">
                                    <p className="small-text-16">
                                        <i className="fa fa-car"></i> {lang("VIN Number:")} <span>{vin}</span>
                                    </p>
                                </div>
                                <div className="pass-fail-button-top">
                                    {/* <span className="check-btn">
                                        <button className="btn" type="button" onClick={this.toggleCheckManuallyModal} disabled={reportStatus !== ''}>
                                            Check Manually
                                        </button>
                                    </span> 
                                    <span className="pass-btn">
                                        <button className="btn" type="button" onClick={this.togglePassActionModal} disabled={reportStatus !== ''}>
                                            Pass
                                        </button>
                                    </span>
                                    <span className="fail-btn">
                                        <button className="btn" type="button" onClick={this.toggleFailActionModal} disabled={reportStatus !== ''}>
                                            Fail
                                        </button>
                                    </span>*/}
                                    <span className="pass-btn">
                                        <button className="btn" type="button" onClick={this.togglePassActionModal} disabled={reportStatus !== ''}>
                                            Send
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="car-image-show-content">
                                <div className="row align-items-center">
                                </div>
                            </div>
                            <div className="under-body-image-slider">
                                <div className="image-editor" >
                                    <Carousel selectedItem={currentSlide} showThumbs={false}>
                                        {imageArr.length ?
                                            imageArr.map((data, i) => {

                                                const imageNameDisplay = data.imageLink.split('/').slice(-1);
                                                const fileNameWithExtension = data.imageLink.split('/').pop();
                                                const imageNameDisplayWithoutExtension = fileNameWithExtension
                                                    .replace(/^Report_/, "")      // Remove 'Report_' prefix
                                                    .replace(/\.jpg$/, "")        // Remove '.jpg' extension
                                                    .replace(/_(\d+)/, "-$1"); 
                                                return <div
                                                    key={data.uuid}
                                                    onClick={() => this.handleImageClick(data.uuid,imageNameDisplay)}
                                                    type="button"
                                                    data-toggle="modal"
                                                >
                                                    <p className='display_part_name'>{imageNameDisplay}</p>
                                                    <img src={data.imageName} id={data.uuid} alt="Underbody" />
                                                </div>
                                            })
                                            :
                                            <div>
                                                <img src='/assets/images/default-image.png' alt='default-image' />
                                            </div>
                                        }
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal className='custom-modal-editor' size='xl' isOpen={this.state.modal} toggle={() => this.toggleModal()}>
                    <ModalHeader toggle={() => this.toggleModal()}>
                        <div className='row align-items-center justify-content-center'>
                            <div className='col-4'>
                                <div className="all-title car-title">
                                    <p className="small-text-16">
                                        <i className="fa fa-car"></i> {lang("VIN Number:")} <span>{vin}</span>
                                    </p>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='text-center'>
                                <p className="small-text-16">{this.state.imageNameDisplay} </p>
                                </div>
                            </div>
                            <div className='col-4'>
                                <div className='custom_header_wrapper'>
                                <select className='type_select' value={this.state.selectedType} onChange={(e)=>{this.handleSelectChange(e)}}>
                                    <option value="damage">Damage</option>
                                    <option value="paint">Paint</option>
                                </select>
                                    <button
                                        type="button"
                                        className="close"
                                        data-dismiss="modal"
                                        aria-label="Close"
                                        onClick={() => this.toggleModal()}
                                    >
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                    <span className="zoom-btn">
                                        <button
                                            className="btn reset"
                                            type="button"
                                            onClick={() => this.resetZoom()}
                                        >
                                            {lang("Reset")}
                                        </button>
                                    </span>
                                    <span className="pass-btn">
                                        <button
                                            className="btn"
                                            type="button"
                                            onClick={() => this.updateImage()}
                                        >
                                            {lang("Save")}
                                        </button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </ModalHeader>

                    <ModalBody className='pt-0'>
                        <div className="car-modal-image">
                            {isImageClicked == 1 && <ImageEditorPage handleTrueOrFalse={handleTrueOrFalse} key={this.state.imageEditorKey} closeModal={() => this.toggleModal()} handlePaintCountChange ={(type,count)=>this.handlePaintCountChange(type,count)} handleSelectValueChangeFromBoxSelect ={(type)=>this.handleSelectValueChangeFromBoxSelect(type)} selectedType={this.state.selectedType} canvasRef={this.canvasRef} isSaving={isSaving} />}
                        </div>
                    </ModalBody>
                </Modal>
                <Modal className='custom-modal' id="failModal" isOpen={showPassModal} centered='true' toggle={() => this.togglePassActionModal()} >
                    <ModalBody className='pt-0'>
                        {loading &&
                            <div className="loader-main">
                                <Spinner color="light">
                                    <span></span>
                                </Spinner>
                            </div>
                        }
                        <h6 className="second-title">{lang("Are you sure you want to pass this image?")}</h6>
                    </ModalBody>
                    <ModalFooter className='modal-footer'>
                        <button className="btn-red btn" onClick={() => {
                            this.setState({ loading: true }, () => {
                                this.handleAction('pass')
                            })
                        }
                        }>
                            {lang("Yes")}
                        </button>
                        <a className="btn-gray btn" onClick={this.togglePassActionModal}>{lang("No")}</a>
                    </ModalFooter>
                </Modal>

                <Modal className='custom-modal' id="checkCustom-Modal" isOpen={showFailModal} centered='true' toggle={() => this.toggleFailActionModal()} >
                    <ModalBody className='pt-0'>
                        {loading &&
                            <div className="loader-main">
                                <Spinner color="light">
                                    <span></span>
                                </Spinner>
                            </div>
                        }
                        <h6 className="second-title">{lang("Are you sure you want to fail this image?")}</h6>
                    </ModalBody>
                    <ModalFooter className='modal-footer'>
                        <span className="btn-red btn" onClick={() => this.handleAction('fail')}>{lang("Yes")}</span>
                        <a className="btn-gray btn" onClick={this.toggleFailActionModal}>{lang("No")}</a>
                    </ModalFooter>
                </Modal>
                <Modal className='custom-modal' id="checkCustom-Modal" isOpen={showCheckManuallyModal} centered='true' toggle={() => this.toggleCheckManuallyModal()} >
                    <ModalBody className='pt-0'>
                        {loading &&
                            <div className="loader-main">
                                <Spinner color="light">
                                    <span></span>
                                </Spinner>
                            </div>
                        }
                        <h6 className="second-title">{lang("Are you sure you want to check manually this image?")}</h6>
                    </ModalBody>
                    <ModalFooter className='modal-footer'>
                        <span className="btn-red btn" onClick={() => this.handleAction('check_manually')}>{lang("Yes")}</span>
                        <a className="btn-gray btn" onClick={this.toggleCheckManuallyModal}>{lang("No")}</a>
                    </ModalFooter>
                </Modal>

            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pendingNotification: state.notificationReducer.pendingNotification,
        completedNotification: state.notificationReducer.completedNotification,
        ImageArr: state.imageArrReducers.ImageArr,
        clickedImage: state.clickedImageReducer.clickedImage,
        getToken: state.tokenReducer.getToken,
    };
};

export default withRouter(connect(mapStateToProps, { setPendingNotification, setCompletedNotification, setImageArray, setClickedImage, setToken })(PendingView));
