import React, { Component } from 'react'

export class AllSupportAccount extends Component {
  render() {
    return (
      <div>AllSupportAccount</div>
    )
  }
}

export default AllSupportAccount