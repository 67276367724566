import Cookies from 'js-cookie';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import withRouter from '../../withRouter';
import { setImageArray } from '../../Redux/actions/imageArr';
import { setClickedImage } from '../../Redux/actions/clickedImage';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import LanguageContext from "../../context/LanguageProvider";

export class CompletedView extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            imageArr: [],
            currentSlide: 0,
            imageEditorKey: 1,
        };
    }

    componentDidMount() {
        const { ImageArr } = this.props;
        this.setState({ imageArr: ImageArr });
    }

    componentDidUpdate(prevProps) {
        const { ImageArr } = this.props;
        if (prevProps.ImageArr !== ImageArr) {
            this.setState({ imageArr: ImageArr });
        }
    }


    handlePrevious = () => {
        this.setState((prevState) => ({
            currentSlide: prevState.currentSlide - 1,
        }));
    };

    handleNext = () => {
        this.setState((prevState) => ({
            currentSlide: prevState.currentSlide + 1,
        }));
    };
    render() {
        const { imageArr, currentSlide } = this.state;
        const imageUrl = Cookies.get('completedImageUrl');
        const vin = Cookies.get('completedVin')
        const { lang } = this.context;
        const reportStatus = Cookies.get('completedReportStatus')

        return (
            <>

                <div className="col-lg-8 col-md-12 col-sm-12 d-flex all-underbody-col image-modal-col">
                    <div className="card-main card">
                        <div className="card-header">
                            <div className="all-title-close-btn">
                                <div className="all-title car-title">
                                    <p className="small-text-16">
                                        <i className="fa fa-car"></i> {lang("VIN Number:")} <span>{vin}</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="car-image-show-content">
                                <div className="row align-items-center">
                                    <div className="col-lg-12 col-md-6 col-sm-8">
                                        {/* <div className="pass-fail-button-top">
                                            {reportStatus == 'pass' ?
                                                <div className="col-12 p-0">
                                                    <div className="pass-fail-message-wrapper">
                                                        <div className="message-top pass-message-top">
                                                            <p>{lang("This car image is passed successfully.")}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                reportStatus == 'fail' ?
                                                    <div className="col-12 p-0">
                                                        <div className="pass-fail-message-wrapper">
                                                            <div className="message-top fail-message-top">
                                                                <p>{lang("This car image is failed successfully.")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    :
                                                    <div className="col-12 p-0">
                                                        <div className="pass-fail-message-wrapper">
                                                            <div className="message-top check-message-top">
                                                                <p>{lang("This car image is checked manually.")}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="under-body-image-slider">
                                <div className="image-editor">
                                    <Carousel selectedItem={currentSlide} showThumbs={false}>
                                        {imageArr.map((data, i) => {
                                            const imageNameDisplay = data.imageLink.split('/').slice(-1);
                                            // <div key={data.uuid} >
                                            //     <img src={data.imageName} id={data.uuid} alt="Underbody" />
                                            // </div>
                                            return<div
                                            key={data.uuid}
                                            // onClick={() => this.handleImageClick(data.uuid,imageNameDisplay)}
                                            type="button"
                                            data-toggle="modal"
                                        >
                                            <p className='display_part_name'>{imageNameDisplay}</p>
                                            <img src={data.imageName} id={data.uuid} alt="Underbody" />
                                        </div>
    })}
                                    </Carousel>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        ImageArr: state.imageArrReducers.ImageArr,
        clickedImage: state.clickedImageReducer.clickedImage,
    };
};

export default withRouter(connect(mapStateToProps, { setImageArray, setClickedImage })(CompletedView));
