import React, { Component } from 'react';
import Cookies from 'js-cookie';
import withRouter from '../../withRouter';
import Header from '../common/header/Header';
import axios from 'axios';
import { configuration } from '../../appConfig';
import noNotificationImage from '../../assets/images/no-noification.png'
import Fail from '../fail/Fail';
import Pass from '../pass/Pass';
import CheckManually from '../check manually/CheckManually';
import { Spinner } from 'reactstrap';
import { connect } from "react-redux";
import { setPendingNotification, setCompletedNotification,setAlldNotification } from "../../Redux/actions/notificationAction";
import { setImageArray } from '../../Redux/actions/imageArr';
import { setClickedImage } from '../../Redux/actions/clickedImage';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import CompletedView from '../completedView/CompletedView';
import PendingView from '../pendingView/PendingView';
import "firebase/compat/database";
import LanguageContext from "../../context/LanguageProvider";
import { setToken } from "../../Redux/actions/token";
import notificationSound from '../../assets/notification-sound/S1.mp3';
import notificationIcon from '../../assets/images/ADIIcon.png';
import { CookiesRemove } from '../../CookiesRemove';

export class Index extends Component {
  static contextType = LanguageContext;
  constructor(props) {
    super(props);
    this.state = {
      allNotification: [],
      showUnderbodyImage: true,
      imageEditorKey: 1,
      completedKey: 1,
      pendingKey: 1,
      modalEditorKey: 1,
      action: '',
      loading: false,
      actionTaken: '',
      modalOpen: false,
      checkAction: 'check_manually',
      locked: '',
      lockNotification: false,
      isWorking: false
    }
  }
  checkPendingNotifications() {
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const pendingNotification = [...this.props.pendingNotification];
    const currentTime = new Date();
    const fiveMinutes = 5 * 60 * 1000;
    const nonEmptyReportStatus = [...this.props.completedNotification];
    const emptyReportStatus = [];
    const openedVin = Cookies.get('openedVin')
    pendingNotification.forEach(item => {
      const createdAtTime = new Date(item.created_at);
      const timeDifference = currentTime.getTime() - createdAtTime.getTime();
      if (timeDifference > fiveMinutes) {
        const uuid = item.uuid;
        const url = `${backendUrl+configuration.getSingleNotification}?uuid=${uuid || ''}`;
        const token = this.props.getToken
        const headers = {
          token: `${token}`, 
        };
        if (openedVin != uuid) {
          axios.get(url, { headers }).then((res) => {
            this.setState({
              loading: false
            });
            if (res.data.status == 200) {
              if(!this.state.isWorking) {
                const actionTakenUrl =backendUrl+ configuration.notifyActionTaken;
                const partnerId = Cookies.get('partnerId')
                const payload = {
                  uuid: uuid,
                  reportStatus: this.state.checkAction,
                  partnerId: partnerId,
                  actionTaken: 1,
                };
                const token = this.props.getToken
                const headers = {
                  token: `${token}`,
                };
                axios.post(actionTakenUrl, payload, { headers })
                  .then((res) => {
                    if (res.data.status == 200) {
                      const newItem = { ...item, reportStatus: this.state.checkAction, created_at: res.data.actionTaken_date };
                      Cookies.set('actionTakenTime', res.data.actionTaken_date)
                      nonEmptyReportStatus.push(newItem);
                      this.props.setCompletedNotification(nonEmptyReportStatus);
                      Cookies.remove('checkAction')
                    }
                    // if (res.data.status == 401) {
                    //   CookiesRemove()
                    //   toastAlert(res.data.message,"error");  
                    //      this.props.router.navigate('/');
                    // }
                  })
                  .catch((err) => {
                    console.log(err, 'Error calling actionTaken API');
                  });
              }
            }
          }).catch((err) => {
            this.setState({
              loading: false
            });
            console.log(err, 'err');
          });

          if(this.state.isWorking) {
            emptyReportStatus.push(item);
          }
        }
        else {
          emptyReportStatus.push(item);
        }
      } else {
        emptyReportStatus.push(item);
      }
    });
    this.props.setPendingNotification(emptyReportStatus);
  }
  componentDidMount = async () => {
    await this.getAllNotifications(true);
    await this.getWorkingStatus();
    this.addEventListenerOnNavigator();
    // this.interval = setInterval(() => this.getAllNotifications(false), 9000);
  };
  
  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval when the component is unmounted
  }
  

  addEventListenerOnNavigator = () => {
    navigator?.serviceWorker?.addEventListener('message', (event) => {
      if(event?.data?.data.hasOwnProperty('enabledVin')) {
        this.setState({
          isWorking: event?.data?.data.enabledVin === 'false' ? false : true
        }
        );
      } else if(event.data.data.actionFlag) {
        this.getAllNotifications(false);
      }
      // else{
      //   this.interval = setInterval(this.getAllNotifications(false), 1 * 1000)
      // }
    });
  }
  handleGetSingleNotification = (uuid) => {
    const { lang } = this.context;
    this.setState({
      loading: true
    });
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = `${backendUrl + configuration.getSingleNotification}?uuid=${uuid || ''}`;
    const token = this.props.getToken
    const headers = {
      token: `${token}`,
    };
    axios.get(url, { headers }).then((res) => {
      toastAlert(lang('Notification Received'), 'success');
      this.setState({
        loading: false
      });
      this.props.setPendingNotification([...this.props.pendingNotification, res.data.data]);
    }).catch((err) => {
      this.setState({
        loading: false
      });
      console.log(err, 'err');
    });
  }
  getAllNotifications = (showLoader) => {
    if(showLoader) {
      this.setState({ loading: true });
    }
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const partnerId = Cookies.get('partnerId');
    const url = `${backendUrl+configuration.getAllNotifications}?partnerId=${partnerId || ''}`;
    const token = this.props.getToken
    const headers = {
      token: `${token}`,
    };
    axios.get(url, { headers }).then(res => {
      if (res.data.status == 200) {
        this.setState({
          allNotification: res.data.data,
          loading: false,
        });
        if (res.data.is_new_vin_scan) {
          if ("Notification" in window) {
            if (Notification.permission === "granted") {
              // Play audio and show notification if permission is granted
              playNotificationSound();
              showNotification();
            } else if (Notification.permission !== "denied") {
              // Request permission if not already denied
              Notification.requestPermission().then(permission => {
                if (permission === "granted") {
                  playNotificationSound();
                  showNotification();
                }
              });
            }
          }
          function playNotificationSound() {
            // This function should be called in response to a user action
            const audio = new Audio(notificationSound);
            audio.play()
            .catch(error => {
              console.error('Error playing notification sound:', error);
            });
          }
          
          function showNotification() {
            const notification = new Notification("New Order Arrived", {
              body: "New VIN to check. Please review it!",
              icon: notificationIcon // Optional: You can add an icon for the notification
            });
          
            notification.onclick = () => {
              window.focus(); // Focus the browser window if notification is clicked
            };
          }
        }
      
        const emptyReportStatus = [];
        const nonEmptyReportStatus = [];
        res.data.data.forEach(notification => {
          if (notification.reportStatus === '') {
            emptyReportStatus.push(notification);
            this.props.setPendingNotification(emptyReportStatus);
          } else {
            nonEmptyReportStatus.push(notification);
            this.props.setCompletedNotification(nonEmptyReportStatus);
          }
        });
      } 
      else {
      // CookiesRemove()
        this.setState({
          loading: false
        })
      }
    }).catch(err => {
      this.setState({ loading: false });
      console.log(err.data);
    });
  }
  handleVINClick = (value) => {
    this.setState({
      imageEditorKey: this.state.imageEditorKey + 1,
      action: '',
      showUnderbodyImage: false,
      modalOpen: true,
      loading: true,
    });
    const uuid = value.uuid;
    const vin = value.vin;
    const reportStatus = value.reportStatus;
    Cookies.set('vin', vin);
    Cookies.set('uuid', uuid);
    Cookies.set('reportStatus', reportStatus);
    Cookies.set('openedVin', uuid);
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = `${backendUrl+configuration.getSingleNotification}?uuid=${uuid || ''}`;
    const token = this.props.getToken
    const headers = {
      token: `${token}`,
    };
    axios.get(url, { headers })
      .then((result) => {
        if (result.data.status == 200) {
          this.setState({
            loading: false,
            pendingKey: this.state.pendingKey + 1,
          });
          this.props.setImageArray(result.data.data.imageName)
        }
        // if (result.data.status == 401) {
        //   CookiesRemove()
        //   toastAlert(result.data.message,"error");  
        //     this.props.router.navigate('/');
 
        // }
      })
      .catch((err) => {
        console.log(err);
      });
  }
  handleCompletedClick = (completedValue) => {
    this.setState({
      loading: true
    })
    const uuid = completedValue.uuid
    const vin = completedValue.vin
    const reportStatus = completedValue.reportStatus
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = `${backendUrl+configuration.getSingleNotification}?uuid=${uuid || ''}`;
    const token = this.props.getToken
    const headers = {
      token: `${token}`, 
    };
    axios.get(url, { headers }).then((res) => {
      this.setState({
        completedKey: this.state.completedKey + 1,
        loading: false,
        action: 'completedClick',
        showUnderbodyImage: false,
      })
      if (res.data.status == 200) {
        Cookies.set('completedImageUrl', res.data.data.imageURL)
        Cookies.set('completedVin', vin)
        Cookies.set('completedReportStatus', reportStatus)
        this.props.setImageArray(res.data.data.imageName)
      }
      // if (res.data.status == 401) {
      //   CookiesRemove()
      //   toastAlert(res.data.message,"error");  
      //      this.props.router.navigate('/');
      // }
    }).catch((err) => {
      this.setState({
        loading: false
      })
    });
  }
  handleTrueOrFalse = (action) => {
    this.setState({
      action: action
    });
  }
  handleButtonClick = () => {
    this.setState({
      showUnderbodyImage: true
    })
  }
  removeVin = () => {
    const uuid = Cookies.get('uuid');
    const partnerId = Cookies.get('partnerId');
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = backendUrl+configuration.unlockUserNotification;
    const token = this.props.getToken
    const headers = {
      token: `${token}`, 
    };
    let formData = new FormData();
    formData.append('uuid', uuid);
    formData.append('partnerId', partnerId);
    axios.post(url, formData, { headers }).then((res) => {
      // console.log(res.data);
      if (res.data.status == 200) {
        Cookies.remove('openedVin')
        this.setState({
          locked: `${res.data.is_locked}`
        })
      }
      // if (res.data.status == 401) {
      //   CookiesRemove()
      //   toastAlert(res.data.message,"error");  
      //      this.props.router.navigate('/');
      // }
    }).catch((err) => {
      console.log(err);
    });
  }
  handleLockNotification = (value) => {
    this.setState({
      locked: value
    })
  };
  toggleWorking = (e) => {
    const { checked } = e.target;
    this.setState({
      isWorking: checked,
      loading: true
    }, () => this.setWorkingStatus());
  };
  setWorkingStatus = () => {
    const { isWorking } = this.state;
    const partnerId = Cookies.get('partnerId');
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = backendUrl+ configuration.setWorkingStatus;

    const payload = {
      partnerId,
      enable: isWorking ?? false,
      token:this.props.getToken
    };
    axios.post(url, payload)
      .then((res) => {
        if(res.data.status === 200) {
          toastAlert("Working status updated successfully!",'success');
        } else {
          this.setState({
            isWorking: !this.state.isWorking
          });
          toastAlert("Something went wrong. Please try again later.","error");  
        }
      })
      .catch((err) => {
        toastAlert("Something went wrong. Please try again later.","error");
      })
      .finally(() => {
        this.setState({
          loading: false
        })
      })
      ;
  };

  getWorkingStatus = async () => {
    const partnerId = Cookies.get('partnerId');
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = `${backendUrl+configuration.getWorkingStatus}?partnerId=${partnerId}`;
    const token = this.props.getToken || Cookies.get('token');

    const headers = {
      token
    }
    await axios.get(url,{ headers })
      .then((res) => {
        if(res.data.status === 200) {
          this.setState({
            isWorking: res.data.data.is_enabled
          }
          // ,() => {
          //   this.interval = setInterval(this.checkPendingNotifications.bind(this), 1 * 5000)
          // }
          );
        }
        // if (res.data.status == 404) {
        //   CookiesRemove()
        //   toastAlert("Unothorized user","error");  
        //     this.props.router.navigate('/');
        // }
      })
      .catch((err) => {
        console.log(err,"err");
      })
  }

  render() {
    const { showUnderbodyImage, loading, isWorking } = this.state
    const clickedUUID = Cookies.get('clickedUUID')
    const clickedNotification = Cookies.get('clickedNotification')
    const pendingNotification = this.props.pendingNotification.reduce((accumulator, currentNotification) => {
      const isDuplicate = accumulator.some(notification => notification.uuid === currentNotification.uuid);
      if (!isDuplicate) {
        accumulator.push(currentNotification);
      }
      return accumulator;
    }, [])
      .sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          const timeA = new Date(a.date).getTime();
          const timeB = new Date(b.date).getTime();
          return timeB - timeA;
        }
      });
    const completedNotification = this.props.completedNotification.reduce((accumulator, currentNotification) => {
      const isDuplicate = accumulator.some(notification => notification.uuid === currentNotification.uuid);
      if (!isDuplicate) {
        accumulator.push(currentNotification);
      }
      return accumulator;
    }, [])
      .sort((a, b) => {
        const dateA = new Date(a.created_at);
        const dateB = new Date(b.created_at);
        if (dateA > dateB) {
          return -1;
        } else if (dateA < dateB) {
          return 1;
        } else {
          const timeA = new Date(a.date).getTime();
          const timeB = new Date(b.date).getTime();
          return timeB - timeA;
        }
      });
    const { lang } = this.context;
    return (
      <div>
        <Header />
        <main className="main-content">
          {loading && <div className="loader-main">
            <Spinner color="light">
              <span></span>
            </Spinner>
          </div>}

          <section className="all-notifications-wrapper">
            <div className="container-fluid">
              <div className="row">
                <div className="col-lg-4 col-md-12 col-sm-12 d-flex all-vins-col notification-col">
                  <button className="btn all-win-top-btn all-Vins-btn">
                    {lang("All VINs")}
                  </button>
                  <div className="notification-drawer-main">
                    <div className="card-main card notification-card">
                      <div className="card-header">
                        <div className="all-title-close-btn">
                          <div className="all-title">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                              <li className="nav-item" role="presentation">
                                <a className="nav-link active" id="home-tab" data-toggle="tab"
                                  href="#home" role="tab" aria-controls="home"
                                  aria-selected="true" onClick={this.handleButtonClick}>{lang("Pending")}</a>
                              </li>
                              <li className="nav-item" role="presentation">
                                <a className="nav-link" id="profile-tab" data-toggle="tab"
                                  href="#profile" role="tab" aria-controls="profile"
                                  aria-selected="false" onClick={this.handleButtonClick}>{lang("Completed")}</a>
                              </li>
                              <li className="nav-item">
                                <div className="custom-toggle-button">
                                  <input 
                                    type="checkbox"
                                    id="working"
                                    onChange={(e) => this.toggleWorking(e)}
                                    checked={isWorking}
                                  />
                                  <label className="" htmlFor="working">{lang("working")}</label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="drawer-close-btn">
                            <button className="btn closeHeaderDrawer">
                              <i className="fa fa-times"></i>
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="card-body notification-card-body">
                        <div className="tab-content" id="myTabContent">
                          <div className="tab-pane fade show active" id="home" role="tabpanel"
                            aria-labelledby="home-tab">
                            <div className="all-notifications-content">
                              {pendingNotification.length > 0 ? (
                                pendingNotification.map((value, i) => {
                                  const receivedDateTime = new Date(value.created_at);
                                  const currentTime = new Date();
                                  const timeDiffInSeconds = Math.floor((currentTime - receivedDateTime) / 1000); 

                                  let displayText = '';

                                  if (timeDiffInSeconds < 60) {
                                    displayText = `${timeDiffInSeconds} ${timeDiffInSeconds > 1 ? `${lang("seconds")}` : `${lang("second")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60) {
                                    const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                                    displayText = `${timeDiffInMinutes} ${timeDiffInMinutes > 1 ? `${lang("minutes")}` : `${lang("minute")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60 * 24) {
                                    const timeDiffInHours = Math.floor(timeDiffInSeconds / (60 * 60));
                                    displayText = `${timeDiffInHours} ${timeDiffInHours > 1 ? `${lang("hours")}` : `${lang("hour")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60 * 24 * 30) {
                                    const timeDiffInDays = Math.floor(timeDiffInSeconds / (60 * 60 * 24));
                                    displayText = `${timeDiffInDays} ${timeDiffInDays > 1 ? `${lang("days")}` : `${lang("day")}`} ${lang("ago")}`;
                                  } else {
                                    const timeDiffInMonths = Math.floor(timeDiffInSeconds / (60 * 60 * 24 * 30));
                                    displayText = `${timeDiffInMonths} ${timeDiffInMonths > 1 ? `${lang("months")}` : `${lang("month")}`} ${lang("ago")}`;
                                  }

                                  return (
                                    <div
                                      key={i}
                                      className={`notification-icon-content-main ${parseInt(value.is_locked) === 1   ? 'disable-notification lock-icon' : ''} ${value.reportStatus === 'pass'
                                        ? 'passNotification'
                                        : value.reportStatus === 'fail'
                                          ? 'failNotification'
                                          : ''
                                        }`}
                                      id={`${value.uuid}`}
                                      type="button"
                                      data-toggle="modal"
                                    >
                                      <div
                                        className="notifications-icon-main"
                                      >
                                        <i className={`fa ${parseInt(value.is_locked) === 1   ? 'fa-lock' : 'fa-bell'}`}></i>
                                      </div>
                                      <div className="notification-vin-content w-100" onClick={() => { if (parseInt(value.is_locked) === 1  ) { } else { this.handleVINClick(value) } }}>
                                        <p className="vin-number cursor small-text-16">
                                          {lang("VIN")} <span>{value.vin}</span>
                                        </p>
                                        <span className="time-span">{displayText}</span>
                                        <div className="car-details">
                                          <p className="small-text-14">
                                            <span>{lang("PartnerId")}: </span> {value.parent_partner_id == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {value.parent_partner_id}</span>}
                                          </p>
                                          <p className="small-text-14">
                                            <span>{lang("Make")}: </span> {value.make == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {value.make}</span>}
                                          </p>
                                          <p className="small-text-14 border-0">
                                            <span> {lang("Model")}: </span> {value.model == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {value.model}</span>}
                                          </p>
                                          <p className="small-text-14">
                                            <span>{lang("Color")}: </span> {value.color == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {value.color}</span>}
                                          </p>
                                         
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no-notifications-wrapper">
                                  <div className="no-notifications-card">
                                    <img src={noNotificationImage} alt="No Notifications" />
                                    <p className="small-text-16">{lang("No Notifications")}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="tab-pane fade" id="profile" role="tabpanel"
                            aria-labelledby="profile-tab">
                            <div className="all-notifications-content">
                              <div className="completed-notification-legends-main">
                                <div className="legend-main green-legend">
                                  <i className="fa fa-circle"></i>
                                  <p>{lang("Pass")}</p>
                                </div>
                                <div className="legend-main red-legend">
                                  <i className="fa fa-circle"></i>
                                  <p>{lang("Fail")}</p>
                                </div>
                                <div className="legend-main yellow-legend">
                                  <i className="fa fa-circle"></i>
                                  <p>{lang("Check manually")}</p>
                                </div>
                              </div>
                              {completedNotification.length > 0 ? (
                                completedNotification.map((completedValue, i) => {
                                  const receivedDateTime = new Date(completedValue.created_at);
                                  const currentTime = new Date();
                                  const timeDiffInSeconds = Math.floor((currentTime - receivedDateTime) / 1000); // Time difference in seconds

                                  let displayText = '';

                                  if (timeDiffInSeconds < 60) {
                                    displayText = `${timeDiffInSeconds} ${timeDiffInSeconds > 1 ? `${lang("seconds")}` : `${lang("second")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60) {
                                    const timeDiffInMinutes = Math.floor(timeDiffInSeconds / 60);
                                    displayText = `${timeDiffInMinutes} ${timeDiffInMinutes > 1 ? `${lang("minutes")}` : `${lang("minute")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60 * 24) {
                                    const timeDiffInHours = Math.floor(timeDiffInSeconds / (60 * 60));
                                    displayText = `${timeDiffInHours} ${timeDiffInHours > 1 ? `${lang("hours")}` : `${lang("hour")}`} ${lang("ago")}`;
                                  } else if (timeDiffInSeconds < 60 * 60 * 24 * 30) {
                                    const timeDiffInDays = Math.floor(timeDiffInSeconds / (60 * 60 * 24));
                                    displayText = `${timeDiffInDays} ${timeDiffInDays > 1 ? `${lang("days")}` : `${lang("day")}`} ${lang("ago")}`;
                                  } else {
                                    const timeDiffInMonths = Math.floor(timeDiffInSeconds / (60 * 60 * 24 * 30));
                                    displayText = `${timeDiffInMonths} ${timeDiffInMonths > 1 ? `${lang("months")}` : `${lang("month")}`} ${lang("ago")}`;
                                  }




                                  return (
                                    <div
                                      key={i}
                                      className={`notification-icon-content-main ${completedValue.reportStatus === 'pass'
                                        ? 'passNotification'
                                        : completedValue.reportStatus === 'fail'
                                          ? 'failNotification'
                                          : 'checkManually'
                                        }`}
                                      id={`${completedValue.uuid}`}
                                    >
                                      <div className="notifications-icon-main">
                                        <i
                                          className={`fa fa-${completedValue.reportStatus === 'pass' ? 'check'
                                            :
                                            completedValue.reportStatus === 'fail' ? 'times'
                                              :
                                              'hand-paper-o'
                                            }`}></i>
                                      </div>
                                      <div className="notification-vin-content">
                                        <p className="vin-number cursor small-text-16" onClick={() => this.handleCompletedClick(completedValue)}>
                                          {lang("VIN")} - <span>{completedValue.vin}</span>
                                        </p>
                                        <span className="time-span">{displayText}</span>
                                        <div className="car-details">
                                          <p className="small-text-14">
                                            <span> {lang("Make")}:</span> {completedValue.make == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {completedValue.make}</span>}
                                          </p>
                                          <p className="small-text-14">
                                            <span>{lang("Model")}:</span> {completedValue.model == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {completedValue.model}</span>}
                                          </p>
                                          <p className="small-text-14">
                                            <span>{lang("Color")}:</span>    {completedValue.color == '' ? <span className="small-text-14 card-dash-span">-</span> : <span className="small-text-14">&nbsp; {completedValue.color}</span>}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                })
                              ) : (
                                <div className="no-notifications-wrapper">
                                  <div className="no-notifications-card">
                                    <img src={noNotificationImage} alt="No Notifications" />
                                    <p className="small-text-16">{lang("No Notifications")}</p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="drawer-overlay"></div>
                </div>
                {showUnderbodyImage ? (
                  <div className="col-lg-8 col-md-12 col-sm-12 d-flex all-underbody-col image-modal-col">
                    <div className="card-main card ">
                      <div className="card-body">
                        <div className="place-image-here-content">
                          <div className="empty-card">
                            <img src="assets/images/car-image-empty.svg" alt="Click on VIN to view image" />
                          </div>
                          <h6 className="second-title">{lang("Click on VIN to view underbody image")}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                ) :
                  this.state.action == '' ?
                    <PendingView handleTrueOrFalse={this.handleTrueOrFalse} key={this.state.pendingKey} />
                    : this.state.action == 'completedClick' ? <CompletedView key={this.state.completedKey} />
                      : this.state.action == 'pass' ? <Pass />
                        : this.state.action == 'fail' ? <Fail />
                          : <CheckManually />
                }
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    pendingNotification: state.notificationReducer.pendingNotification,
    completedNotification: state.notificationReducer.completedNotification,
    allNotification: state.notificationReducer.allNotification,
    ImageArr: state.imageArrReducers.ImageArr,
    getToken: state.tokenReducer.getToken,
  };
};

export default withRouter(connect(mapStateToProps, { setPendingNotification, setCompletedNotification,setAlldNotification,setImageArray, setClickedImage, setToken })(Index));
