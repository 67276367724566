import React, { Component } from 'react';
import loginImage from '../../assets/images/login-img.svg';
import dnsLogo from '../../assets/images/dns-logo.png';
import '../login/Login.css';
import { configuration } from '../../appConfig';
import axios from 'axios';
import withRouter from '../../withRouter'
import Cookies from 'js-cookie';
import { CookiesRemove } from '../../CookiesRemove';
import toastAlert from '../../helper/ToastAlert/ToastAlert';
import { Spinner } from 'reactstrap';
import LanguageContext from "../../context/LanguageProvider";
import { setToken } from "../../Redux/actions/token";
import { connect } from "react-redux";
import { messaging } from "../../firebase";
import { getToken } from "firebase/messaging";

export class Login extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.state = {
            email_id: '',
            password: '',
            partner_id: '',
            emailError: '',
            passwordError: '',
            partnerIdError: '',
            showPassword: false,
            loading: false,
            state: {},
        };
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleToggleShowPassword = this.handleToggleShowPassword.bind(this);
    }

    componentDidMount = () => {
        const savedEmail = sessionStorage.getItem('saved_email');
        const savedPartnerId = sessionStorage.getItem('saved_partnerId');

        if (savedEmail) {
            this.setState({ email_id: savedEmail });
        }

        if (savedPartnerId) {
            this.setState({ partner_id: savedPartnerId });
        }
    }

    componentDidUpdate(prevProps) {
        const isLoggedIn = Cookies.get('isLoggedIn');
        const { router } = this.props;
        if (isLoggedIn) {  
            router.navigate('/index');
        };
     }
  handleToggleShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    }
    handleEmailChange = (event) => {
        const emailValue = event.target.value.replace(/^\s+/g, '');
        sessionStorage.setItem('saved_email', emailValue);
        this.setState({ email_id: emailValue });
    }
    handleClick = () => {
        if (Cookies.get('token') === "") {
            this.initializeFirebaseMessaging()
        }
    }
    initializeFirebaseMessaging = async () => {
        const FIREBASE_API_KEY  = process.env.REACT_APP_FIREBASE_API_KEY;
        await getToken(messaging, {
            vapidKey: FIREBASE_API_KEY
        }).then((currentToken) => {
            if (currentToken) {
                Cookies.set('token', currentToken);
                this.props.setToken(currentToken)
            } else {
                Notification.requestPermission().then((permission) => {
                    if (permission === 'granted') {
                        console.info('Permission granted. Requesting token...');
                        getToken(messaging).then((newToken) => {
                            Cookies.set('token', newToken);
                            this.props.setToken(newToken)
                            this.setState({
                                token: newToken
                            });
                        }).catch((err) => {
                            console.log('An error occurred while retrieving token.', err);
                        });
                    } else {
                        toastAlert("Please allow notifications to receive updates and important information from this website.", 'error')
                    }
                });
            }
        }).catch((err) => {
            toastAlert("Please allow notifications to receive updates and important information from this website. Also, avoid opening this website in a private tab as it may prevent notifications from working properly.", 'error')
            console.log('An error occurred while retrieving token.', err);
        });

        navigator.serviceWorker.addEventListener('message', (event) => {
           const clickedUUId1 = Cookies.get('clickedUUID')
            const clickedNotification1 = Cookies.get('clickedNotification')
             Cookies.set('clickedNotification', event.data.data.is_locked ? event.data.data.is_locked : clickedNotification1)
            Cookies.set('clickedUUID', event.data.data.uuid ? event.data.data.uuid : clickedUUId1)
            const uuid = event.data.data['gcm.notification.datamain'];
            if (uuid) {
                this.handleGetSingleNotification(uuid);
                if (this.userInteracted) {
                    this.playSound();
                } else {
                    this.pendingSound = true;
                }
            }
        });
    };
 validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
 handlePasswordChange = (event) => {
        this.setState({ password: event.target.value });
    }
    handlePartnerIdChange = (event) => {
        const partnerIdValue = event.target.value.replace(/^\s+/g, '');
        sessionStorage.setItem('saved_partnerId', partnerIdValue);
        this.setState({ partner_id: partnerIdValue },
            // this.getBackendURL(partnerIdValue)
        );
    }

    getBackendURL = async (partnerId) => {
        let baseUrl = window.location.origin;
        let url = `${baseUrl}/partnerWiseUrl.json`;
    
        try {
            const response = await axios.get(url);
            if (response?.data) {
                // console.log(response.data, "log_response");
                
                const partner = response.data.find(p => p.partner_id === partnerId);
                // console.log(partner, "urlllll");
    
                // Get the backend URL
                let backendUrl = partner && partner.backend_url;
    
                // Store it in localStorage for future use
                localStorage.setItem("dynamic_partner_url", backendUrl);
    
                // Return the backend URL
                return backendUrl;
            }
        } catch (err) {
            console.error(err);
            return null;  // Return null or handle error
        }
    };
    
    
 handleLogin = async (event) => {
        event.preventDefault();
        const { lang } = this.context;
        this.setState({ emailError: '', passwordError: '' });
        let isValid = true;
        if (this.state.email_id.trim() === '') {
            this.setState({ emailError: lang('Email is required') });
            isValid = false;
        } else if (!this.validateEmail(this.state.email_id.trim())) {
            this.setState({ emailError: lang('Please enter a valid email') });
            isValid = false;
        } else {
            this.setState({ emailError: '' });
        }
        if (this.state.password.trim() === '') {
            this.setState({ passwordError: lang('Password is required') });
            isValid = false;
        } else {
            this.setState({ passwordError: '' });
        }
        if (this.state.partner_id.trim() === '') {
            this.setState({ partnerIdError: lang('Partner ID is required') });
            isValid = false;
        } else {
            this.setState({ partnerIdError: '' });
        }
        if (isValid) {
            try {
                // Set partner ID in localStorage
                // const partnerId = this.state.partner_id.trim();
                // localStorage.setItem('client_partner_id', partnerId);
    
                // Fetch dynamic backend URL based on partner ID
                const backendUrl = await this.getBackendURL(this.state.partner_id.trim());
                // console.log("Fetched Backend URL:", backendUrl);
    
                // If URL is fetched, continue with login
                if (backendUrl) {
                    const checkToken = this.props.getToken;
                    if (checkToken === "") {
                        await this.initializeFirebaseMessaging();
                        await this.calledLogin(backendUrl);
                    } else {
                        this.calledLogin(backendUrl);
                    }
                } else {
                    this.setState({ partnerIdError: lang('Could not fetch backend URL') });
                }
            } catch (error) {
                console.error("Error fetching backend URL:", error);
                this.setState({ partnerIdError: lang('Failed to retrieve backend URL') });
            }
        }
    };

    calledLogin = async (backendUrl) => {
        const { lang } = this.context;

        const url = backendUrl + configuration.userLogin;
        const payload = {
            username: this.state.email_id,
            partnerId: this.state.partner_id,
            password: this.state.password
        };
        this.setState({
            loading: true
        })
        await axios.post(url, payload)
            .then(res => {
                if (res.data.result === 'success') {
                    this.handleGetToken(backendUrl)
                    this.setState({
                        loading: false
                    })
                    toastAlert(lang('Login Successfully'), 'success');
                    const state = {
                        client: res.data.client,
                        pdfFooter: res.data.pdfFooter,
                        pdfHeader: res.data.pdfHeader,
                    };
                    const isLoggedIn = res.data.result === 'success';
                    Cookies.set('isLoggedIn', isLoggedIn);
                    Cookies.set('partnerId', this.state.partner_id);
                    this.props.router.navigate('/index', {
                        state: state
                    });
                    sessionStorage.removeItem('saved_email');
                    sessionStorage.removeItem('saved_partnerId');
                } else {
                    CookiesRemove();
                    this.setState({
                        loading: false
                    })
                    this.props.router.navigate('/');
                    toastAlert(lang('Unauthorized User'), 'error');
                }
            })
            .catch(error => {
                this.setState({
                    loading: false
                })
                console.log(error, 'error');
            });
    }

    handleGetToken = (backendUrl) => {
        const url = backendUrl+configuration.getToken
        const token = this.props.getToken
        const payload = {
            token: token,
            partnerId: this.state.partner_id
        }
        axios.post(url, payload).then((res) => {
            this.setState({
                loading: false
            })
        }).catch((err) => {
            this.setState({
                loading: false
            })
            console.log(err);
        })
    }


    render() {
        const { showPassword, loading, partner_id, email_id, password } = this.state
        const { lang } = this.context;
        return (
            <div>
                <section className="login-wrapper">
                    {loading && <div className="loader-main">
                        <Spinner color="light">
                            <span></span>
                        </Spinner>
                    </div>}
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-lg-6 col-md-12 col-sm-12 login-col">
                                <div className="login-img">
                                    <img src={loginImage} alt="loginImage" />
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12 col-sm-12">
                                <div className="login-form">
                                    <form onSubmit={(event) => this.handleLogin(event)}>
                                        <div className="card card-main">
                                            <div className="card-header">
                                                <div className="dns-logo">
                                                    <img src={dnsLogo} alt="DNS logo" />
                                                </div>
                                            </div>
                                            <div className="card-body">
                                                <div className="login-form-input">
                                                    <div className="row justify-content-center">
                                                        <div className="col-lg-10 col-md-12 col-sm-12">
                                                            <div className="custom-input">
                                                                <div className="input-group">
                                                                    <div className="span-input">
                                                                        <i className="fa fa-user"></i>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={lang('Partner Id')}
                                                                        value={partner_id}
                                                                        onChange={this.handlePartnerIdChange}
                                                                    />
                                                                </div>
                                                                {this.state.partnerIdError && (<span className="error-message">{this.state.partnerIdError}</span>)}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10 col-md-12 col-sm-12">
                                                            <div className="custom-input">
                                                                <div className="input-group">
                                                                    <div className="span-input">
                                                                        <i className="fa fa-envelope"></i>
                                                                    </div>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        placeholder={lang('Email')}
                                                                        autoComplete="false"
                                                                        onChange={this.handleEmailChange}
                                                                        value={email_id}
                                                                    />
                                                                </div>
                                                                {this.state.emailError && (<span className="error-message">{this.state.emailError}</span>)}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10 col-md-12 col-sm-12">
                                                            <div className="custom-input">
                                                                <div className="input-group">
                                                                    <div className="span-input">
                                                                        <i className="fa fa-key"></i>
                                                                    </div>
                                                                    <input
                                                                        type={showPassword ? 'text' : 'password'}
                                                                        className="form-control"
                                                                        placeholder={lang('Password')}
                                                                        autoComplete="false"
                                                                        value={password}
                                                                        onChange={this.handlePasswordChange}
                                                                        onClick={() => this.handleClick()}
                                                                    />
                                                                    <div className="eye-icon span-input end-input" onClick={this.handleToggleShowPassword}>
                                                                        <i className={showPassword ? 'fa fa-eye' : 'fa fa-eye-slash'}></i>
                                                                    </div>
                                                                </div>
                                                                {this.state.passwordError && (<span className="error-message">{this.state.passwordError}</span>)}
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-10 col-md-12 col-sm-12">
                                                            <div className="login-btn">

                                                                <button className="btn red-btn" type='submit'>
                                                                    {lang("Login")}
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getToken: state.tokenReducer.getToken,
        isAuthenticated: state.auth.isAuthenticated
    };
};

export default withRouter(connect(mapStateToProps, { setToken })(Login));
