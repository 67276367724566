// export const url = 'https://drivenscan.com'; // production
// export const url = 'https://driveandscan.de'; // current staging 
export const url = 'https://backend.drivenscan.live'; // current Live Production
// export const url = 'https://betaqa.drivenscan.com'; // staging


export const configuration = {
    userLogin: '/api_v2/loginCheck.php',
    getAllNotifications: '/api_v2/notifications/getAllNotifications.php',
    getSingleNotification: '/api_v2/notifications/getSingleNotification.php',
    actionTaken: '/api_v2/notifications/actionTaken.php',
    autoActionTaken: '/api_v2/notifications/autoActionTaken.php',
    // checkUserNotification: '/api_v2/notifications/checkNotification.php',
    checkUserNotification: '/api_v2/notifications/checkNotificationsNew.php',
    unlockUserNotification: '/api_v2/notifications/unlockNotificationNew.php',
    getToken: '/api_v2/notifications/saveToken.php',
    removeToken: '/api_v2/notifications/deleteToken.php',
    // getBase64: "/api_v2/imageToBase64.php", // Get image base 64
    getBase64: "/api_v2/notifications/imageToBase64.php", // Get image base 64
    updateImage: '/api_v2/notifications/imageSave.php',
    notifyActionTaken: '/api_v2/notifications/notifyActionTaken.php',

    // Working switch
    setWorkingStatus: '/api_v2/notifications/enableVin.php',
    getWorkingStatus: "/api_v2/notifications/getWorkingFlag.php"
}