import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import Login from './components/login/Login';
import Index from './components/index/Index';
import SendMessage from './components/SendMessage';
import CreateSupportAccount from './pages/supportAccount/CreateSupportAccount';
import AllSupportAccount from './pages/supportAccount/AllSupportAccount';

function AppRoutes() {
  const PrivateRoute = (props) => {
      const isLoggedIn = Cookies.get('isLoggedIn');
      return isLoggedIn ? props.element : <Navigate to="/login" />;
  };
  return (
    <>
      <Router>
        <Suspense fallback={<div>Loading...</div>}>
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" />} />
            <Route path="/index" element={<PrivateRoute element={<Index />} />} />
            <Route path="/partnerId/create" element={<PrivateRoute element={<CreateSupportAccount />} />} />
            <Route path="/partnerId/all" element={<PrivateRoute element={<AllSupportAccount />} />} />
          </Routes>
        </Suspense>
      </Router>
    </>
  );
}

export default AppRoutes;
