import './App.css';
import AppRoutes from './AppRoutes';
import { Provider } from 'react-redux';
import SendMessage from '../src/components/SendMessage';
import { PersistGate } from 'redux-persist/integration/react';
import { store,persistor } from "../src/Redux/store";

function App() {

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <div className="App">
          <AppRoutes />
          <SendMessage
          // getNotificationData={getNotificationData}
          />
        </div>
      </PersistGate>
    </Provider>
  );
}

export default App;
