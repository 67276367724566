import axios from "axios";
import React, { Component } from "react";
import { createContext } from "react";
import Cookies from 'js-cookie';
const LanguageContext = createContext({});
class LanguageProvider extends Component {
  constructor() {
    super();
    this.state = {
      lang: null,
      words: {},
    };
  }

  componentDidMount = () => {
    this.setState({
      lang: Cookies.get('lang') || "US",
    });
    this.getLang();
  };

  getLang = async () => {
    let baseUrl = window.location.origin;
    let url = `${baseUrl}/lang.json`;
    axios
      .get(url)
      .then((res) => {
        if (res?.data) {
          this.setState({
            words: res.data,
          });
        }
      })
      .catch((err) => {
        console.log(err, "err");
      });
  };

  lang = (word) => {
    const { lang, words } = this.state;
    return words && words[word] && words[word][lang]
      ? words[word][lang]
      :  word;
  };

  userLanguageChange = (lang) => {
    this.setState({
      lang: lang,
    });
    Cookies.set("lang", lang);
  };

  render() {
    const { lang } = this.state;
    return (
      <LanguageContext.Provider
        value={{
          userLanguageChange: this.userLanguageChange,
          lang: this.lang,
          language: lang,
        }}
      >
        {this.props.children}
      </LanguageContext.Provider>
    );
  }
}

export default LanguageContext;
export { LanguageProvider };
