import React, { Component } from "react";
import Header from "../../components/common/header/Header";
import "./CreateSupportAccount.css";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

export class CreateSupportAccount extends Component {
    constructor(props) {
        super(props);
        this.state = {
          supportPartnerId: '',
          supportEmailAddress: '',
          supportAccountPassword: '',
          supportAccountConfirmPassword: '',
          partnerId: '',
          isShowPassword: false,
          isShowConfirmPassword: false,
          modal: false,
          error: [],
        };
    }
    handelClick = () => {
        this.setState({
            modal: true,
        });
    };

    handleChange = (e) => {
      const {name, value} = e.target;
      this.setState({
        [name]: value 
      });
    };

    handleSubmit = (event) => {
      event.preventDefault();
      const {supportPartnerId, supportEmailAddress, supportAccountPassword, supportAccountConfirmPassword, partnerId} = this.state;

      let error = [];

      if(!supportPartnerId.trim()) {
        error.push("#emptySupportPartnerId");
      };

      if(!supportEmailAddress.trim()) {
        error.push("#emptySupportEmailAddress");
      };

      if(!supportAccountPassword.trim()) {
        error.push("#emptySupportAccountPassword");
      };

      if(!supportAccountConfirmPassword.trim()) {
        error.push("#emptySupportAccountConfirmPassword");
      };

      if(!partnerId.trim()) {
        error.push("#emptyPartnerId");
      };

      if(supportAccountConfirmPassword !== supportAccountPassword) {
        error.push("#mismatchPasswordAndConfirmPassword");
      };

      if(error.length) {
        this.setState({
          error
        });
        return false;
      } else {
        /* API call for create support account */
      }


    };

    toggleModal = () => {
      this.setState({
        modal: !this.state.modal
      });
    };

    showHidePassword = (stateName) => {
      this.setState({
        [stateName]: !this.state[stateName]
      })
    } 

    render() {

      const {supportPartnerId, supportEmailAddress, supportAccountPassword, supportAccountConfirmPassword, partnerId, error, isShowPassword, isShowConfirmPassword} = this.state;
      
      return (
        <>
                <div>
                    <Header />
                </div>
                <div className="user_create_wrapper">
                    <div className="main_user_profile">
                        <div className="container-fluid">
                            <div className="justify-content-center row">
                                <div className="col-lg-10 col-md-12 col-sm-12">
                                    <nav>
                                        <div className="nav nav-tabs" id="nav-tab" role="tablist">
                                            <button
                                                className="nav-link active"
                                                id="nav-partner-id-creation-tab"
                                                data-toggle="tab"
                                                data-target="#nav-partner-id-creation"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-partner-id-creation"
                                                aria-selected="true"
                                            >
                                                Create Partner Id
                                            </button>
                                            <button
                                                className="nav-link "
                                                id="nav-view-partner-id-tab"
                                                data-toggle="tab"
                                                data-target="#nav-view-partner-id"
                                                type="button"
                                                role="tab"
                                                aria-controls="nav-view-partner-id"
                                                aria-selected="false"
                                            >
                                                View Partner Id
                                            </button>
                                        </div>
                                    </nav>
                                    <div className="custom_card">
                                        <div className="tab-content">
                                            <div
                                                className="tab-pane fade show active"
                                                id="nav-partner-id-creation"
                                                role="tabpanel"
                                                aria-labelledby="nav-partner-id-creation-tab"
                                            >
                                                <div>
                                                    <form action='' autocomplete="off" onSubmit={(event) => this.handleSubmit(event)}>
                                                        <div className="row p-0">
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="mb-3 form-group">
                                                                    <label
                                                                        for="supportPartnerId"
                                                                        className="form-label"
                                                                    >
                                                                        Support PartnerId
                                                                    </label>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control"
                                                                        id="supportPartnerId"
                                                                        name='supportPartnerId'
                                                                        value={supportPartnerId}
                                                                        onChange={(e) => this.handleChange(e)}
                                                                    />
                                                                {error.includes('#emptySupportPartnerId') && <p className="text-danger text-left">Support partnerId is required</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="mb-3 form-group">
                                                                    <label
                                                                        for="supportEmailAddress"
                                                                        className="form-label"
                                                                    >
                                                                        Email Address
                                                                    </label>
                                                                    <input
                                                                        type="email"
                                                                        className="form-control"
                                                                        id="supportEmailAddress"
                                                                        name='supportEmailAddress'
                                                                        value={supportEmailAddress}
                                                                        onChange={(e) => this.handleChange(e)}
                                                                        autoComplete="new-password"
                                                                    />
                                                                {error.includes('#emptySupportEmailAddress') && <p className="text-danger text-left">Email address is required</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="mb-3 form-group">
                                                                    <div className="position-relative">
                                                                        <label
                                                                            for="supportPassword"
                                                                            className="form-label"
                                                                        >
                                                                            Password
                                                                        </label>
                                                                        <input
                                                                            type={isShowPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            id="supportPassword"
                                                                            name='supportAccountPassword'
                                                                            value={supportAccountPassword}
                                                                            onChange={(e) => this.handleChange(e)}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        <div className="password_button">
                                                                            <button
                                                                                className="btn"
                                                                                type="button"
                                                                                onClick={() => this.showHidePassword('isShowPassword')}
                                                                            >
                                                                                <i className={isShowPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                {error.includes('#emptySupportAccountPassword') && <p className="text-danger text-left">Password is required</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="mb-3 form-group">
                                                                    <div className="position-relative">
                                                                        <label
                                                                            for="supportConfirmPassword"
                                                                            className="form-label"
                                                                        >
                                                                            Confirm Password
                                                                        </label>
                                                                        <input
                                                                            type={isShowConfirmPassword ? "text" : "password"}
                                                                            className="form-control"
                                                                            id="supportConfirmPassword"
                                                                            name='supportAccountConfirmPassword'
                                                                            value={supportAccountConfirmPassword}
                                                                            onChange={(e) => this.handleChange(e)}
                                                                            autoComplete="new-password"
                                                                        />
                                                                        <div className="password_button">
                                                                            <button
                                                                                className="btn"
                                                                                type="button"
                                                                                onClick={() => this.showHidePassword('isShowConfirmPassword')}
                                                                            >
                                                                                <i className={isShowConfirmPassword ? "fa fa-eye" : "fa fa-eye-slash"}></i>
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                {error.includes('#emptySupportAccountConfirmPassword') ? <p className="text-danger text-left">Confirm password is required</p> : 
                                                                (error.includes('#mismatchPasswordAndConfirmPassword') && <p className="text-danger text-left">Your password and confirm password do not match.</p>)}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-6 col-md-6 col-sm-12">
                                                                <div className="mb-3 form-group">
                                                                    <label
                                                                        for="disabledSelect"
                                                                        className="form-label"
                                                                    >
                                                                        Partner Id
                                                                    </label>
                                                                    <select
                                                                        id="disabledSelect"
                                                                        className="form-select"
                                                                        name='partnerId'
                                                                        value={partnerId}
                                                                        onChange={(e) => this.handleChange(e)}
                                                                    >
                                                                        <option value="">
                                                                          Select Partner Id
                                                                        </option>
                                                                        <option value="002-sa">
                                                                            002-sa
                                                                        </option>
                                                                        <option value="5-200126">
                                                                            5-200126
                                                                        </option>
                                                                        <option value="12-210615">
                                                                            12-210615
                                                                        </option>
                                                                        <option value="11-210601">
                                                                            11-210601
                                                                        </option>
                                                                        <option value="xclaims-bmw">
                                                                            xclaims-bmw
                                                                        </option>
                                                                    </select>
                                                                 {error.includes('#emptyPartnerId') && <p className="text-danger text-left">PartnerId is required</p>}
                                                                </div>
                                                            </div>
                                                            <div className="col-lg-12 col-md-12 col-sm-12 text-right">
                                                                <button
                                                                    type="submit"
                                                                    className="btn submit_button"
                                                                >
                                                                    Submit
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                            <div
                                                className="tab-pane fade"
                                                id="nav-view-partner-id"
                                                role="tabpanel"
                                                aria-labelledby="nav-view-partner-id-tab"
                                            >
                                                <div>
                                                    <div className="table-responsive">
                                                        <table className="table table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    <th>Sr. No</th>
                                                                    <th>Support Partner ID</th>
                                                                    <th>Support Email Address</th>
                                                                    <th>Date & Time</th>
                                                                    <th>PartnerId</th>
                                                                    <th>Action</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td>1</td>
                                                                    <td>002-sa</td>
                                                                    <td>sa@adi-gmbh.Component</td>
                                                                    <td>11-02-2024 08:24:00</td>
                                                                    <td>002-sa</td>
                                                                    <td>
                                                                        <div>
                                                                            <button
                                                                                className="edit_btn"
                                                                                onClick={() =>
                                                                                    this.handelClick()
                                                                                }
                                                                            >
                                                                                <i className="fa fa-pencil"></i>
                                                                            </button>
                                                                            <button className="deactive_btn">
                                                                                <i className="fa fa-times"></i>
                                                                            </button>
                                                                            <button className="active_btn">
                                                                                <i className="fa fa-check"></i>
                                                                            </button>
                                                                        </div>
                                                                    </td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal
                    centered={true}
                    size="lg"
                    isOpen={this.state.modal}
                    className="theme_modal_user"
                    backdrop="static"
                    toggle={() => this.toggleModal()}
                >
                    <ModalHeader
                        toggle={() => this.toggleModal()}
                        close={
                            <button
                                type="button"
                                className="close_btn"
                                onClick={() => this.toggleModal()}
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        }
                    >
                        <div className="all-title car-title">
                            <p className="small-text-16">
                                <i className="fa fa-user"></i> Update Support Partner Id
                            </p>
                        </div>
                    </ModalHeader>
                    <form>
                        <ModalBody>
                            <div>
                                <div className="row p-0">
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3 form-group">
                                            <label for="exampleInputEmail1" className="form-label">
                                                Partner ID
                                            </label>
                                            <input type="text" className="form-control" disabled />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3 form-group">
                                            <label for="EmailAddressInput" className="form-label">
                                                Email Address
                                            </label>
                                            <input
                                                type="email"
                                                className="form-control"
                                                id="EmailAddressInput"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3 form-group">
                                            <div className="position-relative">
                                                <label for="PasswordInput" className="form-label">
                                                    Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="PasswordInput"
                                                />
                                                <div className="password_button">
                                                    <button className="btn">
                                                        <i className="fa fa-eye-slash"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3 form-group">
                                            <div className="position-relative">
                                                <label
                                                    for="ConfirmPasswordInput"
                                                    className="form-label"
                                                >
                                                    Confirm Password
                                                </label>
                                                <input
                                                    type="password"
                                                    className="form-control"
                                                    id="ConfirmPasswordInput"
                                                />
                                                <div className="password_button">
                                                    <button className="btn">
                                                        <i className="fa fa-eye"></i>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12">
                                        <div className="mb-3 form-group">
                                            <label for="disabledSelect" className="form-label">
                                                Template
                                            </label>
                                            <select id="disabledSelect" className="form-select">
                                                <option>DNS</option>
                                                <option>DE</option>
                                                <option>OTHER</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <div>
                                <button
                                    className="btn btn-md p-2 mr-2 cancel_btn"
                                    onClick={() => this.toggleModal()}
                                >
                                    Cancel
                                </button>
                                <button className="btn btn-md p-2 submit_button">Submit</button>
                            </div>
                        </ModalFooter>
                    </form>
                </Modal>
            </>
        );
    }
}

export default CreateSupportAccount;
