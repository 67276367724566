import React, { Component } from 'react';
import Cookies from 'js-cookie';
import withRouter from '../../../withRouter';
import { CookiesRemove } from '../../../CookiesRemove';
import DnsLogo from '../../../assets/images/dns-logo.png';
import toastAlert from '../../../helper/ToastAlert/ToastAlert';
import axios from 'axios';
import { configuration } from '../../../appConfig';
import LanguageContext from "../../../context/LanguageProvider";
import { setToken } from "../../../Redux/actions/token";
import { connect } from "react-redux";
export class Header extends Component {
    static contextType = LanguageContext;

    handleLogout = () => {
        const { lang } = this.context;
        const backendUrl = localStorage.getItem('dynamic_partner_url')
        const url = backendUrl+configuration.removeToken
        const token = this.props.getToken
        const payload = {
            token: token,
        };

        axios.post(url, payload).then((res) => {
            if ([200,404].includes(res.data.status)) {
                CookiesRemove();
                this.props.setToken("")
                toastAlert(lang('Logout Successfully'), 'success');
                this.props.router.navigate('/');
                localStorage.removeItem("dynamic_partner_url")
            }
        }).catch((err) => {
            CookiesRemove();
            console.log(err, 'err');
        })

    }

    handleClick = (lang) => {
        const { userLanguageChange } = this.context;
        userLanguageChange(lang);
        this.setState({ selectedLanguage: lang });
    };

    handleLanguageClick(lang) {
        const { userLanguageChange } = this.context;
        userLanguageChange(lang);
        localStorage.setItem('lang', lang)
        if (lang == 'KU' || lang == 'AR') {
            document.getElementById("main-app-container").setAttribute("dir", 'rtl') //change direction rtl
        } else {
            document.getElementById("main-app-container").setAttribute("dir", 'ltr') //change direction ltr
        }
        document.getElementById("main-app-container").setAttribute("lang", localStorage.getItem('lang'))
    }
    render() {
        const partnerId = Cookies.get('partnerId')
        const { lang, language } = this.context;
        return (
            <div>
                <header className="header-main">
                    <div className="container-fluid">
                        <div className="row align-items-center">
                            <div className="col-6">
                                <div className="dns-logo">
                                    <img src={DnsLogo} alt="DNS logo" />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className='lang-role-wrapper'>

                                    <div className="profile-dropdown">
                                        <div className="dropdown">
                                            <div className="profile-dropdown-main">
                                                <div className="dropdown">
                                                    <a
                                                        className="btn dropdown-toggle custom-lang-dropdown"
                                                        role="button"
                                                        id="dropdownMenuLink"
                                                        data-toggle="dropdown"
                                                        aria-haspopup="true"
                                                        aria-expanded="false"
                                                    >
                                                        <div className="lang-flag-top">
                                                            <div className="lang-img-wrapper">
                                                                <img className="lang-img" src={`../../../assets/images/${language?.toLowerCase()}.svg`} alt="DE" />
                                                                <p className="small-text-black"><span className="black-select"></span><span className="red-select">
                                                                    {language == 'US' ? 'US' :
                                                                        language == 'AR' ? 'AR' :
                                                                            language == 'KU' ? 'KU' : ''}
                                                                </span> </p>
                                                            </div>
                                                        </div>
                                                    </a>

                                                    <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                                                        <a className="dropdown-item lang-drop-main" onClick={() => this.handleLanguageClick("US")}>
                                                            <div className="lang-flag">
                                                                <img src="../../../assets/images/us.svg" alt="EN" />
                                                            </div>
                                                            <div className="lang-name">
                                                                <p className="small-text-black"><span className="black-select">US</span> </p>
                                                            </div>
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='user-role'>
                                        <div className="user-role-content">
                                            <div className="dropdown">
                                                <button className="btn dropdown-toggle" type="button" id="dropdownMenuButton"
                                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                    <p className="small-text-16">
                                                        {partnerId}
                                                    </p>
                                                    <span className="small-text-14">
                                                        {lang("Admin")}
                                                    </span>
                                                </button>
                                                <div className="dropdown-menu" onClick={this.handleLogout} aria-labelledby="dropdownMenuButton">
                                                    <p>{lang("Logout")}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </header >
            </div >
        );
    }
}

const mapStateToProps = (state) => {
    return {
        getToken: state.tokenReducer.getToken,
    };
};

export default withRouter(connect(mapStateToProps, { setToken })(Header));
