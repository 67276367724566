// index.js
import { combineReducers } from 'redux';
import authReducer from './authReducers';
import notificationReducer from './notificationReducers';
import imageArrReducers from './imageArrReducers';
import clickedImageReducer from './clickedImageReducer';
import tokenReducer from './tokenReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  notificationReducer,
  imageArrReducers,
  clickedImageReducer,
  tokenReducer
});

export default rootReducer;
