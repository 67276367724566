import React, { Component, createRef } from 'react';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditor from 'tui-image-editor';
import 'fabric';
import Cookies from 'js-cookie';
import { configuration } from '../../appConfig';
import axios from 'axios';
import './ImageEditor.css';
import { Spinner } from 'reactstrap';
import { connect } from "react-redux";
import { setPendingNotification, setCompletedNotification } from "../../Redux/actions/notificationAction";
import Swal from 'sweetalert2';
import withRouter from '../../withRouter';
import { setClickedImage } from '../../Redux/actions/clickedImage';
import LanguageContext from "../../context/LanguageProvider";
import { setToken } from "../../Redux/actions/token";
import { CookiesRemove } from '../../CookiesRemove';
import toastAlert from '../../helper/ToastAlert/ToastAlert';

class ImageEditorPage extends Component {
    static contextType = LanguageContext;
    constructor(props) {
        super(props);
        this.imageEditorRef = createRef();
        this.tuiImageEditor = null;
        this.isDragging = false;
        this.lastPosX = 0;
        this.lastPosY = 0;
        this.shapeType = "";
        this.state = {
            showPassModal: false,
            showFailModal: false,
            showCheckManuallyModal: false,
            loading: false,
            actionPassOrFail: {},
            showAlreadyClicked: false,
            ClickedImageName: "",
            paintCount: 0,
            damageCount: 0,
        };
    }
    componentDidMount() {
        this.initializeImageEditor();
        this.attachShapeButtonListeners();
       
        
    }

    componentDidUpdate() {
        this.attachShapeButtonListeners();
    }
    componentDidUpdate(prevProps) {
        // Check if the selectedType prop has changed
        if (prevProps.selectedType !== this.props.selectedType) {
            this.updateStrokeColor(this.props.selectedType);
        }
        this.attachShapeButtonListeners();
    }

    initializeImageEditor() {
        const uuid = Cookies.get('uuid');
        const partnerId = Cookies.get('partnerId');
        const backendUrl = localStorage.getItem('dynamic_partner_url');
        const url = backendUrl + configuration.checkUserNotification;
        const formData = new FormData();
        formData.append('uuid', uuid);
        formData.append('partnerId', partnerId);
        this.setState({
            loading: true,
            damageCount: 0, // State to track box count
            paintCount: 0, // State to track box count
        });
        const token = this.props.getToken;
        const headers = {
            token: `${token}`,
        };

        // Fetch user notifications
        axios.post(url, formData, { headers })
            .then(async (res) => {
                try {
                    if (res.data.status == 200) {
                    const imageURL = this.props?.clickedImage;
                    const imageNameDisplay = imageURL.split('/').slice(-1);
                    const fileNameWithExtension = imageURL.split('/').pop();
                    const imageNameDisplayWithoutExtension = fileNameWithExtension.split('.')[0];
                    this.setState({ ClickedImageName: imageNameDisplayWithoutExtension });
                    this.getBase64URL(imageURL).then((imageInBase64) => {
                        if (imageInBase64) {
                            this.setState({
                                loading: false,
                            });

                            this.tuiImageEditor = this.imageEditorRef && new ImageEditor(this.imageEditorRef.current, {
                                includeUI: {
                                    loadImage: {
                                        path: imageInBase64,
                                        name: 'SampleImage',
                                    },
                                    menu: ['shape'],
                                    initMenu: 'shape',
                                    uiSize: {
                                        width: '100%',
                                        height: '100vh',
                                    },
                                    menuBarPosition: 'left',
                                    usageStatistics: false,
                                    hideHistory: true,
                                    hideDeleteButton: true,
                                    hideDeleteAllButton: true,
                                    cssMaxWidth: 700,
                                    cssMaxHeight: 500,
                                    selectionStyle: {
                                        cornerSize: 5000,
                                        rotatingPointOffset: 70,
                                    },
                                },
                                onError: (error) => {
                                    console.log('An error occurred while loading the image:', error);
                                }
                            });
                            this.updateStrokeColor(this.props.selectedType);
                            this.tuiImageEditor.ui.shape.options = {
                                ... this.tuiImageEditor.ui.shape.options,
                                strokeWidth: 6,
                                useDefaultStroke: true,
                                scaleFactor: 0,
                                // stroke:this.props.type === "paint" ?"#ffbb3b" :"#ff4040"
                            };
                            this.tuiImageEditor.setBrush({ width: 25 });

                            // Hide unwanted UI elements
                            this.hideEditorUI();

                            const canvas = this.tuiImageEditor._graphics?.getCanvas();
                            this.props.canvasRef.current = canvas;
                            canvas.on('mouse:down', this.handleMouseDown.bind(this));
                            canvas.on('mouse:move', this.handleMouseMove.bind(this));
                            canvas.on('mouse:up', this.handleMouseUp.bind(this));
                            canvas.on('mouse:wheel', this.handleMouseWheel.bind(this));
                            this.attachShapeButtonListeners();
                            this.handleResizeCanvas = () => {
                                const canvas = this.props?.canvasRef?.current;
                                if (canvas) {
                                  canvas.width = 700;
                                  canvas.height = 500;
                                }
                              };
                            // Attach custom events
                            this.attachCustomEvents(canvas, this.tuiImageEditor);
                        }
                    });
                    }
                    else {
                        const { lang } = this.context;
                        Swal.fire({
                            title: lang('Already Clicked'),
                            text: lang('Someone is already clicked!'),
                            icon: 'warning',
                            confirmButtonText: 'OK',
                            allowOutsideClick: false,
                            backdrop: true,
                        })
                            .then((result) => {
                                if (result.isConfirmed) {
                                    window.location.href = '/index';
                                }
                            });
                    }
                } catch (error) {
                    console.log('Failed to decode image:', error);
                }
            })
            .catch((err) => {
                this.setState({
                    loading: false,
                });
                console.log(err, 'err');
            });
    }
    updateStrokeColor(selectedType) {
        this.setState({ countType: selectedType })
        const newStrokeColor = selectedType === 'paint' ? '#ffbb3b' : '#ff4040';
        if (this.tuiImageEditor) {
            // Update the stroke color in the shape options
            this.tuiImageEditor.ui.shape.options.stroke = newStrokeColor;

            // If you are in drawing mode, you may need to re-enter it
            this.tuiImageEditor.startDrawingMode('SHAPE', {
                shape: 'rect',
                width: 2,
                stroke: newStrokeColor,
                fill: 'transparent',
            });
        }
    }
    // Method to hide unwanted UI elements
    hideEditorUI() {
        const headerLogo = document.querySelector('.tui-image-editor-header-logo');
        const historyButton = document.querySelector('.tui-image-editor-item.tie-btn-history');
        const deleteButton = document.querySelector('.tui-image-editor-item.tie-btn-delete');
        const deleteAllButton = document.querySelector('.tui-image-editor-item.tie-btn-deleteAll');
        const headerButtonsDiv = document.querySelector('.tui-image-editor-header-buttons');
        const sideHelpMenu = document.querySelector('.tui-image-editor-help-menu');

        if (headerButtonsDiv) {
            headerButtonsDiv.style.display = 'none';
        }
        if (headerLogo) {
            headerLogo.style.display = 'none';
        }
        if (historyButton) {
            historyButton.style.display = 'none';
        }
        if (deleteButton) {
            deleteButton.style.display = 'none';
        }
        if (deleteAllButton) {
            deleteAllButton.style.display = 'none';
        }
        if (sideHelpMenu) {
            sideHelpMenu.style.display = 'none';
        }
    }

    attachCustomEvents(canvas, tuiImageEditor) {
        // Listen for object addition
        canvas.on('object:added', (event) => {
            if (event.target.type === 'rect') {
                event.target.set({ selectable: true }); // Ensure the object is selectable
                canvas.renderAll(); // Re-render to apply changes
                this.incrementBoxCount();
            }
        });
    
        // Listen for object removal
        canvas.on('object:removed', (event) => {
            if (event.target.type === 'rect') {
                const shapeType = event.target.customType; // Retrieve custom attribute
                this.decrementBoxCount(shapeType); // Pass the shape type to decrement function
            }
        });
    
        // Listen for object selection using 'selection:created' and 'selection:updated'
        const handleBoxSelection = (event) => {
            const selectedObject = event.selected[0];
            if (selectedObject && selectedObject.type === 'rect') {
                console.log('Rectangle selected:', selectedObject.customType);
                this.props.handleSelectValueChangeFromBoxSelect(selectedObject.customType)
                // Add any additional logic when a box is selected
            }
        };
    
        // Handle initial selection
        canvas.on('selection:created', handleBoxSelection);
    
        // Handle updated selection (switching from one object to another)
        canvas.on('selection:updated', handleBoxSelection);
    
        // Start drawing mode for rectangles
        tuiImageEditor.startDrawingMode('SHAPE', {
            shape: 'rect',
            width: 2,
            stroke: 'black',
            fill: 'transparent',
        });
    }
    
    
    // Method to increment box count and add custom attributes
incrementBoxCount() {
    const canvas = this.props.canvasRef.current; // Get the canvas reference
    const newShape = canvas.getObjects().slice(-1)[0]; // Get the last added shape

    // Add custom attributes based on the current count type
    if (newShape) {
        newShape.set({ customType: this.state.countType }); // Add custom attribute
        newShape.setCoords(); // Update coordinates
        canvas.renderAll(); // Re-render the canvas
    }

    if (this.state.countType === "paint") {
        this.setState((prevState) => ({
            paintCount: prevState.paintCount + 1,
        }), () => {
            // console.log('paint count:', this.state.paintCount);
            this.props.handlePaintCountChange("paint", this.state.paintCount);
        });
    }
    if (this.state.countType === "damage") {
        this.setState((prevState) => ({
            damageCount: prevState.damageCount + 1,
        }), () => {
            // console.log('Dent count:', this.state.damageCount);
            this.props.handlePaintCountChange("damage", this.state.damageCount);
        });
    }
}
decrementBoxCount(shapeType) {
    if (shapeType === "paint") {
        this.setState((prevState) => ({
            paintCount: Math.max(prevState.paintCount - 1, 0), // Ensure count doesn't go below 0
        }), () => {
            // console.log('paint count:', this.state.paintCount);
            this.props.handlePaintCountChange("paint", this.state.paintCount);
        });
    }
    if (shapeType === "damage") {
        this.setState((prevState) => ({
            damageCount: Math.max(prevState.damageCount - 1, 0), // Ensure count doesn't go below 0
        }), () => {
            // console.log('Dent count:', this.state.damageCount);
            this.props.handlePaintCountChange("damage", this.state.damageCount);
        });
    }
}
    getBase64URL = (imageURL) => {

        return new Promise((resolve, reject) => {
            const [vin, partnerId] = [Cookies.get('vin'), Cookies.get('partnerId')];
            const imageName = imageURL.split('/').slice(-1);
            const backendUrl = localStorage.getItem('dynamic_partner_url')
            const url = `${backendUrl + configuration.getBase64}?partner_id=${partnerId}&vin_number=${vin}&img_name=${imageName}`;
            axios.get(url)
            .then((res) => {
                if(res.data.success == 'true') {
                    resolve(res.data.base64_img)
                }
            })
            .catch((err) => {
                reject(err);
                console.log(err,"err")
            })
        })
    }
    attachShapeButtonListeners() {
        const rectButton = document.querySelector('.tie-shape-button .rect');
        const circleButton = document.querySelector('.tie-shape-button .circle');
        const triangleButton = document.querySelector('.tie-shape-button .triangle');
        const fill = document.querySelector('.tie-color-fill.tui-image-editor-button');
        if (circleButton) {
            circleButton.style.display = 'none';
        }
        if (triangleButton) {
            triangleButton.style.display = 'none';
        }
        if (fill) {
            fill.style.display = 'none';
        }
        if (rectButton && circleButton && triangleButton) {
            rectButton.addEventListener('click', () => {
                this.handleShapeButtonClick('Rectangle');
            });
            circleButton.addEventListener('click', () => {
                this.handleShapeButtonClick('Circle');
            });
            triangleButton.addEventListener('click', () => {
                this.handleShapeButtonClick('Triangle');
            });
        }
    }
    handleShapeButtonClick = (shapeType) => {
        const canvas = this.props.canvasRef.current;
        const objects = canvas.getObjects();
        this.shapeType = shapeType;
    };
    handleMouseDown = (opt) => {
        const canvas = this.props.canvasRef.current;
        const pointer = canvas.getPointer(opt.e);
        const objects = canvas.getObjects();
        const isShapeButtonClicked = objects.some((obj) => {
            return obj.subTarget && obj.subTarget.className === 'tui-image-editor-button';
        });

        if (isShapeButtonClicked) {
            this.isDragging = false;
            canvas.selection = true;
            return;
        }
        const evt = opt.e;
        if (canvas.getZoom() !== 1) {
            if (this.shapeType !== "") {
                this.shapeType = "";
                this.isDragging = false;
                canvas.selection = true;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            } else {
                this.isDragging = true;
                canvas.selection = false;
                this.lastPosX = evt.clientX;
                this.lastPosY = evt.clientY;
            }
            canvas.wrapperEl.style.cursor = 'grabbing';
        } else {
            this.isDragging = false;
            canvas.selection = true;
        }
    };
    handleMouseMove = (opt) => {
        if (this.isDragging) {
            const evt = opt.e;
            const deltaX = evt.clientX - this.lastPosX;
            const deltaY = evt.clientY - this.lastPosY;
            const canvas = this.props.canvasRef.current;
            const canvasViewport = canvas.viewportTransform;
            canvasViewport[4] += deltaX;
            canvasViewport[5] += deltaY;
            canvas.renderAll();
            this.lastPosX = evt.clientX;
            this.lastPosY = evt.clientY;
        }
    };
    handleMouseUp = () => {
        if (this.isDragging) {
            this.isDragging = false;
            const canvas = this.props.canvasRef.current;
            canvas.selection = true;
            canvas.wrapperEl.style.cursor = 'default';
        }
    };
    handleMouseWheel = (opt) => {
        const delta = opt.e.deltaY;
        const canvas = this.props.canvasRef.current;
        const zoom = canvas.getZoom() - delta / 2000;
        const zoomMin = 0.01;
        const zoomMax = 10;
        if (zoom < zoomMin) {
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomMin);
        } else if (zoom > zoomMax) {
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoomMax);
        } else {
            canvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom);
        }
        opt.e.preventDefault();
        opt.e.stopPropagation();
    };
    resetZoom = () => {
        const canvas = this.props.canvasRef.current;
        if (canvas) {
            canvas.setZoom(1);  // Reset zoom to 1 (default zoom level)
            canvas.viewportTransform = [1, 0, 0, 1, 0, 0];  // Reset viewport transform
            canvas.renderAll();  // Re-render the canvas to apply the changes
        }
    };
    render() {
        const {isSaving} = this.props;
        const { loading } = this.state;
        return (
           
            <>
                {(loading || isSaving) && <div className="loader-main">
                    <Spinner color="light">
                        <span></span>
                    </Spinner>
                </div>}
                <div className="card-main1 card1 disable-modal p-1">
                    <div className="card-body1 p-1">
                        <div className="place-image-here-content">
                            {/* <p>
                            {this.state.ClickedImageName} 555555555
                            </p> */}
                            <div className="empty-card" ref={this.imageEditorRef}>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        pendingNotification: state.notificationReducer.pendingNotification,
        completedNotification: state.notificationReducer.completedNotification,
        clickedImage: state.clickedImageReducer.clickedImage,
        getToken: state.tokenReducer.getToken,
    };
};
export default withRouter(connect(mapStateToProps, { setPendingNotification, setCompletedNotification, setClickedImage, setToken })(ImageEditorPage));