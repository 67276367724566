import React, { Component } from "react";
import { getToken } from "firebase/messaging";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { messaging } from "../firebase";
import Cookies from 'js-cookie';
import axios from 'axios';
import { configuration } from '../appConfig';
import toastAlert from '../helper/ToastAlert/ToastAlert';
import { setPendingNotification } from "../Redux/actions/notificationAction";
import { connect } from "react-redux";
import notificationSound from '../assets/notification-sound/S1.mp3';
import LanguageContext from "../context/LanguageProvider";
import { setToken } from "../Redux/actions/token";
import { CookiesRemove } from "../CookiesRemove";
export class SendMessage extends Component {
  static contextType = LanguageContext;

  constructor(props) {
    super(props);
    this.state = {
      loading: false
    };
    // this.playSound = this.playSound.bind(this);
    this.userInteracted = false; 
  }
  handleGetSingleNotification = (uuid) => {
    const { lang } = this.context;
    this.setState({ loading: true });
    const backendUrl = localStorage.getItem('dynamic_partner_url')
    const url = `${backendUrl+configuration.getSingleNotification}?uuid=${uuid || ''}`;
    const headers = {
      token: this.props.getToken 
    };
    axios.get(url, { headers })
      .then((res) => {
        if (res.data.status === 200) {
          toastAlert(lang('New Car Scanned'), 'success');
          this.setState({ loading: false });
          this.props.setPendingNotification([...this.props.pendingNotification, res.data.data]);
        }
        // if (res.data.status == 401) {
        //   CookiesRemove()
        //   toastAlert(res.data.message,"error");  
        //   this.props.router.navigate('/');
        // }
      })
      .catch((err) => {
        console.error('Error fetching notification:', err);
        this.setState({ loading: false });
      });
  };
  initializeFirebaseMessaging = () => {
    const FIREBASE_API_KEY = process.env.REACT_APP_FIREBASE_API_KEY;
    getToken(messaging, { vapidKey: FIREBASE_API_KEY })
      .then((currentToken) => {
        if (currentToken) {
          // console.log(currentToken,"log_51");
          
          Cookies.set('token', currentToken);
          this.props.setToken(currentToken);
        } else {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              getToken(messaging).then((newToken) => {
                Cookies.set('token', newToken);
                this.props.setToken(newToken);
              }).catch((err) => {
                console.error('Error retrieving token:', err);
              });
            } else {
              toastAlert("Please allow notifications to receive updates and important information from this website.", 'error');
            }
          });
        }
      })
      .catch((err) => {
        console.error('Error retrieving token:', err);
        toastAlert("Please allow notifications to receive updates and important information from this website. Also, avoid opening this website in a private tab as it may prevent notifications from working properly.", 'error');
      });
    navigator.serviceWorker.addEventListener('message', (event) => {
      const clickedUUId1 = Cookies.get('clickedUUID');
      const clickedNotification1 = Cookies.get('clickedNotification');
      Cookies.set('clickedNotification', event.data.data.is_locked || clickedNotification1);
      Cookies.set('clickedUUID', event.data.data.uuid || clickedUUId1);
      console.log('Received background message:', event.data.data);
      // const uuid = event.data.data['gcm.notification.datamain'];
      const uuid = event.data.data.enabledVin;
      if (uuid) {
        this.handleGetSingleNotification(uuid); 
        console.log('userInteracted :',this.userInteracted);
        if (this.userInteracted) {
          this.playSound();
        } else {
          this.pendingSound = true;
        }
      }
    });
  };
  playSound() {
    if (!this.userInteracted) {
      console.warn('Sound playback deferred until user interaction.');
      this.pendingSound = true;
      return;
    }
    const audio = new Audio(notificationSound);
    audio.play()
      .catch(error => {
        console.error('Error playing notification sound:', error);
      });
  }
  componentDidMount = async () => {
    await this.initializeFirebaseMessaging(); 

    document.addEventListener('click', this.handleUserInteraction); 
    document.addEventListener('visibilitychange', this.handleVisibilityChange); 
  };
  componentWillUnmount() {
    document.removeEventListener('click', this.handleUserInteraction); 
    document.removeEventListener('visibilitychange', this.handleVisibilityChange); 
  }
  handleUserInteraction = () => {
    this.userInteracted = true; 
    if (this.pendingSound) {
      // this.playSound();
      this.pendingSound = false;
    }
  };
  handleVisibilityChange = () => {
    if (document.visibilityState === 'hidden') {
      // this.playSound();
    }
  };
  render() {
    return (
      <div>
        <ToastContainer />
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    pendingNotification: state.notificationReducer.pendingNotification,
    getToken: state.tokenReducer.getToken,
  };
};
export default connect(mapStateToProps, { setPendingNotification, setToken })(SendMessage);