import Swal from "sweetalert2";
import './style.css'

const Toast = Swal.mixin({
    toast: true,
    icon: 'success',
    title: 'General Title',
    // animation: false,
    position: 'top-right',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
    didOpen: (toast) => {
      toast.addEventListener('mouseenter', Swal.stopTimer)
      toast.addEventListener('mouseleave', Swal.resumeTimer)
    }
});

export default function toastAlert(message, type) {
    Toast.fire({
        // animation: true,
        title: message,
        icon : type
    });
}