export const setPendingNotification = (notification) => ({
  type: 'SET_PENDING_NOTIFICATION',
  payload: notification,
});

export const setCompletedNotification = (notification) => ({
  type: 'SET_COMPLETED_NOTIFICATION',
  payload: notification,
});
export const setAlldNotification = (notification) => ({
  type: 'SET_ALL_NOTIFICATION',
  payload: notification,
});