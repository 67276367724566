import Cookies from 'js-cookie';

export function CookiesRemove() {
    Cookies.remove('isLoggedIn');
    Cookies.set('token',"");
    Cookies.remove('partnerId');
    Cookies.remove('uuid');
    Cookies.remove('vin');
    Cookies.remove('reportStatus');
    Cookies.remove('actionTakenTime');
    Cookies.remove('completedImageUrl');
    Cookies.remove('completedVin');
    Cookies.remove('completedReportStatus');
    Cookies.remove('openedVin');
    Cookies.remove('clickedUUID');
    Cookies.remove('clickedNotification');
    Cookies.remove('clickedCarouselUUID');
}
