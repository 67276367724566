const initialState = {
  pendingNotification: [],
  completedNotification: [],
  allNotification: [],
};

const notificationReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PENDING_NOTIFICATION':
      return {
        ...state,
        pendingNotification: action.payload
      };
    case 'SET_COMPLETED_NOTIFICATION':
      return {
        ...state,
        completedNotification: action.payload
      };
    case 'SET_ALL_NOTIFICATION':
      return {
        ...state,
        allNotification: action.payload
      };
    default:
      return state;
  }
};

export default notificationReducer;
