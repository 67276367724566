import React, { Component } from 'react';
import success from '../../assets/images/sucessfull.png'
import Cookies from 'js-cookie';
import LanguageContext from "../../context/LanguageProvider";
export class Pass extends Component {
    static contextType = LanguageContext;
    render() {
        const vin = Cookies.get('vin')
        const { lang } = this.context;
        return (
            <>
                <div className="col-lg-8 col-md-12 col-sm-12 d-flex all-underbody-col image-modal-col">
                    <div className="card-main card ">
                        <div className="card-body">
                            <div className="place-image-here-content">
                                <div className="empty-card">
                                    <img src={success} alt="Click on VIN to view underbody image" />
                                </div>
                                <div className="all-title car-title mt-3">
                                    <p className="small-text-16"> {lang("Action taken successfully for the")} <span>{lang("VIN")} {vin}</span></p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default Pass;
