const initialState = {
    ImageArr: [],
  };
  
  const imageArrReducers = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_IMAGE_ARRAY':
        return {
          ...state,
          ImageArr: action.payload
        };
      default:
        return state;
    }
  };
  
  export default imageArrReducers;